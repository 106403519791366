.dashboard .dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: black;
}

.dashboard .dashboard-titlePanel {
    display: flex;
    align-items: center; /* This will vertically center the children */
    justify-content: center; /* This will align the children to the start */
    background-color: #035E7B;
    border-bottom: 1rem solid black; 
    box-shadow: 2px 0.5rem 0.1rem rgba(0, 0, 0, 0.30); 
}

.dashboard .dashboard-titlePanel .dashboard-title {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 0.3rem;
    color: white;
}
  
.dashboard .dashboard-titlePanel span:first-child {
    padding: 2rem 1rem 2rem 1rem;
    font-size: 1.5rem;
    color: white;
}
  
/** Menu **/
.dashboard-sidebar {
    width: 10vw;
    padding: 1rem;
    padding-left: 2rem;
    background-color: #035E7B;
    color: white;
    min-height: 100vh;
    overflow-y: scroll;
}

.dashboard-sidebar .dashboard-sidebar-user {
    margin-bottom: 2rem;
}

.dashboard-sidebar .dashboard-sidebar-user .dashboard-sidebar-user-avatar {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 50%;
    border: #FBF5F3 2px solid;
    margin-bottom: 0.5rem;
}

.dashboard-sidebar .dashboard-sidebar-user .dashboard-sidebar-user-name {
    font-size: 1.2rem;
    font-weight: bold;
}

.dashboard-sidebar-user-comment {
    font-size: 0.8rem;
    color: darkgray;
}

.dashboard-sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashboard-sidebar-menu .dashboard-sidebar-menu-item {
    padding: 1rem 1rem 1rem 0.3rem;
    font-size: 1.1rem;
    cursor: pointer; 
}

.dashboard-sidebar-menu .dashboard-sidebar-menu-item a {
    text-decoration: none;
    color: inherit;
}

.dashboard-sidebar-menu .dashboard-sidebar-menu-item span {
    margin-right: 1rem;
}

.dashboard-sidebar-menu-item:hover {
    border-bottom: 1px solid #000022;
}
  
/* Top Panel */
.dashboard-content-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 1rem;
}

/* Left Side */
.dashboard-content-panel .dashboard-left-panel {
    flex: 2.5;
    display: flex;
    flex-direction: column;
}

/* Company Valuation */
.dashboard-content-panel .dashboard-company-valuation {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: rgba(3, 94, 123, 0.8);
    border-radius: 1rem;
    margin: 0 1rem 1rem 0;
}

.dashboard-content-panel .dashboard-company-valuation-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

.dashboard-content-panel .dashboard-valuation-panels {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;
}

.dashboard-content-panel .dashboard-company-valuation .dashboard-valuation-panel-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    background-color: rgba(255,255,255, 0.4);
    border-radius: 1rem;
    padding: 1rem;
    width: 45%;
}

.dashboard-content-panel .dashboard-company-valuation  .dashboard-valuation-panel-title {
    font-size: 1.1rem;
    text-align: left;
    color: black;
    margin-bottom: 0.5rem;
}

.dashboard-content-panel .dashboard-company-valuation .dashboard-company-valuation-value {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.5; 
}

.dashboard-content-panel .dashboard-company-valuation .dashboard-company-valuation-value-curMonth {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.3rem;
}

.dashboard-content-panel .dashboard-company-valuation .dashboard-company-valuation-value-alltime {
    font-size: 1.1rem;
    text-align: center;
}


.dashboard-content-panel .dashboard-company-valuation .at_text {
    font-size: 0.7rem;
    color: black;
}

.dashboard-content-panel .dashboard-company-valuation .dashboard-valuation-stats-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;
}

.dashboard-content-panel .dashboard-company-valuation .dashboard-valuation-stats-panel-detail {
    display: flex;
    flex-direction: column;
    color: white;
    background-color: rgba(255,255,255, 0.4);
    border-radius: 1rem;
    padding: 1rem;
}

.dashboard-content-panel .dashboard-company-valuation .dashboard-company-valuation-graph-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.dashboard-content-panel .dashboard-company-valuation .dashboard-company-valuation-graph-panel .chart {
    flex: 10;
}

/* IPO */
.stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.stats .stats-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: white;
    background-color: rgba(255,255,255, 0.4);
    border-radius: 1rem;
    padding: 1rem;
    margin: 0.5rem;
    width: 90%;
}

.stats-panel-title {
    font-size: 0.8rem;
    text-align: left;
    color: black;
    margin-bottom: 0.1rem;
    text-align: right;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add this line */
}

.stats-panel-value {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    text-align: right;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add this line */
}

.stats-panel-value-change-positive {
    color: green;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    text-align: right;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add this line */
}

.stats-panel-value-change-negative {
    color: red;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    text-align: right;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add this line */
}

.stats .ipoButton {
    background-color: #E97451;
    color: white;
    border: none;
    border-radius: 1rem;
    padding: 1vw;
    margin: 0.5rem;
    cursor: pointer;
    font-size: 0.8vw;
    font-weight: bold;
    width: 50%;
}

.stats .ipoButton:hover {
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.6);
}


/* Right Side */
.dashboard-content-panel .dashboard-right-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.buttonGroupContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

}

.leaderButton {
    background-color: #035E7B;
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    cursor: pointer;
}

/****************** Reporting **************/
.reporting_input {
    width: 45%;
}

.dropzone {
    width: 50%;
}

.dashboard-company-reporting {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: rgba(255, 0, 0, 0.8);
    border-radius: 1rem;
    margin: 0 1rem 1rem 0;
} 

.dashboard-company-reporting .buttonDiv {
    margin-top: 2vw;
    margin-bottom: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-company-reporting .buttonDiv .button {
    width: 60% !important;
    padding: 1rem !important;
    font-size: 1rem !important;
}

.managers_settings_panel {
    display: flex; /* Allow the element to grow and shrink, and set the base size to auto */
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Center items vertically */
    background-color: #2A7221;
    border-radius: 1rem;
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 1rem;
    box-shadow: 2px 0.5rem 0.5rem rgba(0, 0, 0, 0.50); /* Adjust as needed */
    cursor: pointer;
}

.business_settings_panel {
    display: flex; /* Allow the element to grow and shrink, and set the base size to auto */
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Center items vertically */
    background-color: #035E7B;
    border-radius: 1rem;
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 1rem;
    box-shadow: 2px 0.5rem 0.5rem rgba(0, 0, 0, 0.50); /* Adjust as needed */
    margin-top: 1rem;
    cursor: pointer;
}

.buyback_settings_panel {
    display: flex; /* Allow the element to grow and shrink, and set the base size to auto */
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Center items vertically */
    background-color: #A53860;
    border-radius: 1rem;
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 1rem;
    box-shadow: 2px 0.5rem 0.5rem rgba(0, 0, 0, 0.50); /* Adjust as needed */
    margin-top: 1rem;
    cursor: pointer;
}

.settings_panel_title {
    font-size: 1.3rem;
    color: white;
}

.iconSizing {
    font-size: 3rem;
    color: white;
}

/* Mobile Settings */
@media (max-width: 768px) {
    .dashboard-content-panel {
        flex-direction: column;
    }

    .dashboard-content-panel .dashboard-company-valuation {
        margin: 0 1rem 1rem 1rem;
    }

    .dashboard-content-panel .dashboard-company-reporting {
        margin: 0 1rem 1rem 1rem;
    }

    .buttonGroupContainer .leaderButton {
        width: 00%;
    }

    .stats {
        width: 90%;
        margin: 0 auto;
    }
    .stats .ipoButton {
        width: 90%;
        padding: 1rem;
    }

    .stats .ipoButton {
        font-size: 1rem;
    }

    .stats .stats-panel {
        width: 90%;
    }

    .stats .stats-panel-title {
        font-size: 1rem;
        text-align: center;
    }

    .stats .stats-panel-value {
        font-size: 1rem;
        text-align: center;
    }

    .stats .stats-panel-value-change-positive {
        font-size: 0.6rem;
        text-align: center;
    }

    .stats .stats-panel-value-change-negative {
        font-size: 0.6rem;
        text-align: center;
    }
    
    .dashboard-company-valuation .dashboard-valuation-panel-detail {
        width: 90%;
        padding: 0.3rem !important;
    }

    .dashboard-company-valuation .dashboard-company-valuation-value {
        font-size: 1rem !important;
    }

    .dashboard-company-valuation .dashboard-company-valuation-value-curMonth {
        font-size: 1rem;
    }

    .dashboard-company-valuation .dashboard-company-valuation-value-alltime {
        font-size: 0.7rem;
    }

    .dashboard-company-valuation .at_text {
        font-size: 0.7rem;
    }

    .dashboard-company-valuation .dashboard-valuation-stats-panel-detail {
        width: 20% !important;
        padding: 0.3rem !important;
    }

    .dashboard-company-valuation .dashboard-company-valuation-graph-panel {
        flex-direction: column;
    }

    .dashboard-company-valuation .dashboard-company-valuation-graph-panel .chart {
        flex: 1;
    }

    .dashboard-company-valuation .dashboard-company-valuation-graph-panel .chart img {
        width: 100%;
    }

    .dashboard-company-valuation .dashboard-company-valuation-graph-panel .chart .chartTitle {
        font-size: 1.5rem;
    }

    .dashboard-content-panel .dashboard-company-valuation  .dashboard-valuation-panel-title {
        font-size: 0.8rem;
    }
    
    .dashboard-content-panel .dashboard-company-valuation .dashboard-company-valuation-value {
        font-size: 0.9rem !important;
    }
    
    .dashboard-content-panel .dashboard-company-valuation .dashboard-company-valuation-value-curMonth {
        font-size: 0.9rem;
        margin-bottom: 0.1rem;
    }
    
    .dashboard-content-panel .dashboard-company-valuation .dashboard-company-valuation-value-alltime {
        font-size: 0.7rem;
    }
    
    
    .dashboard-content-panel .dashboard-company-valuation .at_text {
        font-size: 0.6rem;
    }
    
    .dashboard-content-panel .dashboard-company-valuation .dashboard-valuation-stats-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 1rem;
    }
    
    .dashboard-content-panel .dashboard-company-valuation .dashboard-valuation-stats-panel-detail {
        display: flex;
        flex-direction: column;
        color: white;
        background-color: rgba(255,255,255, 0.4);
        border-radius: 1rem;
        padding: 1rem;
    }
    /****************** Reporting **************/
    .reporting_input {
        width: 45%;
    }

    .dropzone {
        width: 50%;
    }

    .dashboard-company-reporting {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        background-color: rgba(255, 0, 0, 0.8);
        border-radius: 1rem;
        margin: 0 1rem 1rem 0;
    } 

    .dashboard-company-reporting .buttonDiv {
        margin-top: 2vw;
        margin-bottom: 1vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dashboard-company-reporting .buttonDiv .button {
        color: white;
        width: 25vw;
        border: none;
        background-color: teal;
        border-radius: 25px;
        padding-top: 1vw;
        padding-bottom: 1vw;
        font-size: 1.3vw;
        font-weight: bold;
    }
}
