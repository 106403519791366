.formPanel {
    background-color: white;
    padding: 3rem;
    border-radius: 1rem;
    width: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formPanel .image {
    width: 10rem;
}

.formPanel .title {
    font-size: 2rem;
    color: black;
    font-weight: bolder;
}

.formPanel .subTitle {
    font-size: 1.2rem;
    color: black;
    margin: 1rem 0 1rem 0;
}

.formPanel .inputBox {
    margin-top: 1vw;
    margin-bottom: 1vw;
    text-align: left;
}

.formPanel .input {
    width: 45rem;
}

.formPanel .buttonDiv {
    margin-top: 2vw;
    margin-bottom: 1vw;
}

.formPanel .button {
    color: white;
    width: 25vw;
    border: none;
    background-color: #696cff;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.3vw;
    font-weight: bold;
}

/* For Business Panel */
.formPanel .financialsPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.formPanel .financialsPanel .input {
    width: 100%;
}

.profileImagePanel {
    display: flex;
    flex-direction: column;
}

/* Profile Image */
.profileImage {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
    display: block;
    margin-bottom: 1rem;
}

/* Profile Image */
.profileImagePanel .profileText {
    margin: 0 auto;
    color: black;
}

.ReactTags__tags {
    position: relative;
  }
  
.ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    margin: 0;
    font-size: 1.0rem;
    padding: 1rem 0rem 1rem 0.9rem;
    border: 1px solid gray;
    border-radius: 3px;
    width: 44.1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ReactTags__editInput {
    border-radius: 1px;
}

.ReactTags__editTagInput {
    display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #63bcfd;
    color: white;
    font-size: 1rem;
    display: inline-block;
    padding: 1rem;
    margin: 0 5px;
    border-radius: 5px;
}
.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
}
.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}
.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
}

.ReactTags__error {
    color: #e03131;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile */
@media only screen and (max-width: 700px) {
    .formPanel {
        width: 90%;
    }

    .formPanel .inputBox {
        width: 100%;
    }

    .formPanel .input {
        width: 100%;
    }

    .formPanel .button {
        font-size: 1rem;
        padding: 1rem;
        width: 100%;
    }

    .formPanel .buttonDiv {
        margin-top: 1vw;
        margin-bottom: 1vw;
        width: 50%;
    }

    .formPanel .financialsPanel {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }

    .formPanel .financialsPanel .input {
        width: 100%;
    }

    .profileImage {
        width: 10vw;
        height: 10vw;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 auto;
        display: block;
        margin-bottom: 1rem;
    }

    .profileImagePanel .profileText {
        margin: 0 auto;
        color: black;
    }

    .ReactTags__tagInput {
        width: 100%;
    }

    .ReactTags__tagInput input.ReactTags__tagInputField,
    .ReactTags__tagInput input.ReactTags__tagInputField:focus {

        width: 100%;
    }
    
    .ReactTags__selected span.ReactTags__tag {
        border: 1px solid #ddd;
        background: #63bcfd;
        color: white;
        font-size: 1rem;
        display: inline-block;
        padding: 1rem;
        margin: 0 5px;
        border-radius: 5px;
    }
    .ReactTags__selected a.ReactTags__remove {
        color: #aaa;
        margin-left: 5px;
        cursor: pointer;
    }

    .ReactTags__suggestions ul {
        list-style-type: none;
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
        background: white;
        width: 200px;
    }
    .ReactTags__suggestions ul {
        list-style-type: none;
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
        background: white;
        width: 200px;
    }
    .ReactTags__suggestions li {
        border-bottom: 1px solid #ddd;
        padding: 5px 10px;
        margin: 0;
    }
    .ReactTags__suggestions li mark {
        text-decoration: underline;
        background: none;
        font-weight: 600;
    }
    .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
        background: #b7cfe0;
        cursor: pointer;
    }
    
    .ReactTags__remove {
        border: none;
        cursor: pointer;
        background: none;
        color: white;
    }
    
    .ReactTags__error {
        color: #e03131;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .formPanel .subTitle {
        font-size: 0.9rem;
    }

    .formPanel .interestBox {
        width: 100%;
    }
}