
.importPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2vw;
    margin-bottom: 3vw;
}

.importButton {
    color: white;
    border: 2px solid black;
    background-color: #696cff;
    border-radius: 25px;
    padding: 1vw;
    font-size: 1.3vw;
    font-weight: bold;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.6); /* Add this line */    
}

.buttonPanel {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.buttonPanel .button {
    padding: 1rem;
    height: 25%;
    width: 25%;
    border-radius: 1rem;
    font-size: 1.3vw;
    background-color: orangered;
    color: white;
    font-weight: bold;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.6); /* Add this line */    
}

.buttonPanel .button:hover {

    background-color: #ff7f50;
    color: black;
    box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.6); /* Add this line */    
}

.header_logo {
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 1rem;
}

.dashboard-SMTP {
    margin: auto;
    background-color: red;
    border-radius: 1rem;
    width: 60%;
}

.dashboard-panels {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;
}

.dashboard-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
    padding: 1rem;
    text-align: center;
    color: white;
}

.SMTPButtonDiv {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.button {
    color: white;
    width: 25vw;
    border: none;
    background-color: #696cff;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.3vw;
}