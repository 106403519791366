.container {
    width: 100vw;
    height: 100vh; /* 100% of the viewport height */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.left-column {
    display: flex;
    flex: 1; /* Takes up 1/2 of the available space */
    background-color: #f0f0f0;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.left-column-box {
    padding: 5vw;
}

.right-column {
    flex: 1; /* Takes up 1/2 of the available space */
}

.right-column-box {
    padding-left: 7vw;
    padding-right: 8vw;
}

.logoDiv {
    margin-top: 1vw;
    margin-bottom: 1vw;
    text-align: center;
}

.titleDiv {
    margin-top: 1vw;
    margin-bottom: 1vw;
    text-align: left;
    font-size: large;
    color:rgba(50, 71, 92, 0.87);
}

.subtitleDiv {
    margin-top: 1vw;
    margin-bottom: 1vw;
    text-align: left;
    font-size: medium;
    color: rgba(50, 71, 92, 0.6);
}

.inputBox {
    margin-top: 2vw;
    margin-bottom: 1vw;
    text-align: left;
}

.input {
    width: 25vw;
}

.forgotPasswordBox {
    text-align: left;
    font-size: small;
    color: #696cff;
}

.buttonDiv {
    margin-top: 2vw;
    margin-bottom: 1vw;
}

.button {
    color: white;
    width: 25vw;
    border: none;
    background-color: #696cff;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.3vw;
    font-weight: bold;
}

.button:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    background-color: blue;
}

.buttonPasswordChange {
    color: white;
    width: 25vw;
    border: none;
    background-color: #F28D4A;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.1vw;
    font-weight: bold;
}

.buttonPasswordChange:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    background-color: #FA6806;
}

.buttonReset {
    color: white;
    width: 25vw;
    border: none;
    background-color: #0ABF62;
    border-radius: 25px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1.1vw;
    font-weight: bold;
}

.buttonReset:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    background-color: #14A44D;
}

.left-column {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e59572;
}

.left-column .image {
    width: 90%;
    height: 90%;
    object-fit: cover;
}
    

.left-column .title {   
    color: black;
    font-size: 2rem;
    font-weight: bold;
}

.left-column .subtitle {   
    margin-top: 1rem;
    color: black;
    font-size: 1.1rem;
    font-weight: bold;
}

.googleButton {
    width: 50% !important;
}

.logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    margin: 1rem;
}

.linksPanel {
    display: flex;
    justify-content: space-between;
    width: 25vw;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .containerMobileLogin {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Adjust as needed */
    }
    
    .containerMobileLogin::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%; /* 100% of the viewport height */
        background-image: url('../assets/images/allies_logo.png');
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.65; /* Adjust as needed */
        z-index: -1;
    }

    .containerMobileRegister {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Adjust as needed */
    }
    
    .containerMobileRegister::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%; /* 100% of the viewport height */
        background-image: url('../assets/images/login.png');
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.5; /* Adjust as needed */
        z-index: -1;
    }

    .containerMobileForgot {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Adjust as needed */
    }
    
    .containerMobileForgot::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%; /* 100% of the viewport height */
        background-image: url('../assets/images/3275434.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.5; /* Adjust as needed */
        z-index: -1;
    }

    .containerMobileReset {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Adjust as needed */
    }
    
    .containerMobileReset::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%; /* 100% of the viewport height */
        background-image: url('../assets/images/3293465.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.5; /* Adjust as needed */
        z-index: -1;
    }

    .inputBox {
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: left;
    }

    .input {
        width: 90%;
    }
    
    .googleButton {
        width: 80% !important;
    }

    .button {
        color: white;
        width: 90%;
        border: none;
        background-color: #696cff;
        border-radius: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1rem;
    }
    
    .button:hover {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
        background-color: blue;
    }

    .linksPanel {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-top: 1rem;
    }
    
    .buttonReset {
        color: white;
        width: 90%;
        border: none;
        background-color: #696cff;
        border-radius: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 1rem;
        font-weight: bold;
    }

    .buttonPasswordChange {
        color: white;
        width: 90%;
        border: none;
        background-color: #F28D4A;
        border-radius: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 1rem;
        font-weight: bold;
    }
}